
import moment from "moment";
import { IActivity } from "@/core/data/activity";
import { IActivityRoomPost } from "@/core/data/activityRoomPost";
import ActivityRoomPostService from "@/core/services/ActivityRoomPostService";
import ActivityService from "@/core/services/ActivityService";
import { useAuthStore } from "@/store/useAuthStore";
import Swal from "sweetalert2";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import PostCard from "@/views/studio-lab/components/cards/PostCard.vue";
import type { UploadProps, UploadUserFile } from "element-plus";
import { Plus } from "@element-plus/icons-vue";
import ViewImageModal from "@/views/studio-lab/components/modals/ViewImageModal.vue";
import { IActivityRoom } from "@/core/data/activityRoom";
import UserRoleService from "@/core/services/UserRoleService";
import UserService from "@/core/services/UserService";

interface IUploadFile {
  file: any;
  uid: number;
}

interface IFileAttached {
  id: number;
  fileName: string;
}

export default defineComponent({
  components: { PostCard, Plus, ViewImageModal },
  setup() {
    const authStore = useAuthStore();
    const uploadRef = ref();
    const route = useRoute();
    const loading = ref<boolean>(false);
    const isTeacher = ref<boolean>(false);
    const formPostRef = ref<null | HTMLFormElement>(null);
    const currentActivity = ref<IActivity>();
    const posts = ref<Array<IActivityRoomPost>>([]);
    const dialogImageUrl = ref("");
    const dialogVisible = ref(false);
    const postFileTempList = ref<IUploadFile[]>([]);
    const acceptedTypes = ref(".jpeg,.jpg,.png");
    const maxFileSize = ref(5 * 1024 * 1024); // Specify the maximum file size in bytes (e.g., 5MB)
    const imgAttached = ref<Array<number>>([]);
    const fileAttached = ref<Array<IFileAttached>>([]);
    const currentActivityRoom = ref<IActivityRoom | null>();
    const activityAttachmentbaseURL = ref(
      process.env.VUE_APP_API_URL + "activities/attachments/"
    );
    const activityStatus = ref("");

    const viewImageModalRef = ref();
    const showImage = () => {
      viewImageModalRef.value.showModal();
    };

    const postData = ref<IActivityRoomPost>({
      id: null,
      activity_room: null,
      user: null,
      group: null,
      post: "",
      is_accept: false,
      comments: [],
      attachments: [],
      created_at: new Date(),
    });

    const postRules = computed(() => {
      return {
        post: [
          {
            max: 255,
            message: "Message must be no longer than 255 characters.",
            trigger: "blur",
          },
        ],
      };
    });

    const fileList = ref<UploadUserFile[]>([]);

    const initActivity = () => {
      ActivityService.getActivityById(route.params.id).then((response) => {
        currentActivity.value = response.data;

        getActivityStatus();

        imgAttached.value.splice(0, imgAttached.value.length);
        fileAttached.value.splice(0, fileAttached.value.length);
        currentActivity.value?.attachments?.forEach((item, index) => {
          let splitText = item.file_name.split(".")[1];
          if (
            splitText.toUpperCase() == "JPG" ||
            splitText.toUpperCase() == "JPEG" ||
            splitText.toUpperCase() == "PNG"
          ) {
            imgAttached.value.push(item.id ?? -1);
          } else {
            fileAttached.value.push({
              id: item.id ?? -1,
              fileName: item.file_name,
            });
          }
        });
      });
    };

    const getTeacher = () => {
      UserRoleService.isTeacher().then((response) => {
        isTeacher.value = response.data;
      });
    };

    const getYourActivityRoom = () => {
      UserService.getMyActivityRoom(route.params.id).then((response) => {
        currentActivityRoom.value = response.data;
        initPosts();
      });
    };

    const initPosts = () => {
      if (
        currentActivityRoom.value != null &&
        currentActivityRoom.value != undefined
      ) {
        ActivityRoomPostService.getPostByActivityRoom(
          currentActivityRoom.value?.id
        ).then((response) => {
          posts.value = response.data;
        });
      }
    };

    const onRoomChange = () => {
      initPosts();
    };

    const onSubmitActivityPost = () => {
      if (!formPostRef.value) {
        return;
      }
      formPostRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          var formData = new FormData();

          postFileTempList.value.forEach((postFile) => {
            formData.append("attachments[]", postFile.file);
          });

          if (postData.value.id == null) {
            postData.value.activity_room = currentActivityRoom.value!;
            formData.append("postData", JSON.stringify(postData.value));
            ActivityRoomPostService.createPost(formData)
              .then((response) => {
                postData.value.post = "";
                loading.value = false;
                uploadRef.value!.clearFiles();
                postFileTempList.value = [];
                initPosts();
                Swal.fire({
                  text: "Save complete",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1500,
                });
              })
              .catch(({ response }) => {
                postData.value.post = "";
                loading.value = false;
                uploadRef.value!.clearFiles();
                postFileTempList.value = [];
                if (response.status == 409) {
                  Swal.fire({
                    title: "Warning",
                    text: response.data.message,
                    icon: "warning",
                    buttonsStyling: false,
                    confirmButtonText: "OK",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                  });
                } else {
                  Swal.fire({
                    title: "Error",
                    text: response.data.message,
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "OK",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                  });
                }
              });
          }
        }
      });
    };

    const handleChange = (uploadFile) => {
      if (uploadFile) {
        const isAcceptedType = acceptedTypes.value
          .split(",")
          .some((type) => uploadFile.raw.name.toLowerCase().endsWith(type));
        const isSizeValid = uploadFile.size <= maxFileSize.value;

        if (!isAcceptedType) {
          Swal.fire({
            title: "Warning",
            text: "Only supports .jpeg, .jpg, .png file types.",
            icon: "warning",
            buttonsStyling: false,
            confirmButtonText: "ok",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }

        if (!isSizeValid) {
          Swal.fire({
            title: "Warning",
            text:
              "Max file size is " + maxFileSize.value / (1024 * 1024) + "MB",
            icon: "warning",
            buttonsStyling: false,
            confirmButtonText: "ok",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
        if (isAcceptedType && isSizeValid) {
          let reader = new FileReader();
          reader.readAsDataURL(uploadFile.raw);
          reader.onload = (e) => {
            postFileTempList.value.push({
              file: uploadFile.raw,
              uid: uploadFile.uid,
            });
          };
        } else {
          uploadRef.value.handleRemove(uploadFile);
        }
      }
    };

    const handleRemove: UploadProps["onRemove"] = (uploadFile, uploadFiles) => {
      let index = postFileTempList.value.findIndex(
        (obj) => obj.uid === uploadFile.uid
      );
      if (index != -1) {
        postFileTempList.value.splice(index, 1);
      }
    };

    const handlePictureCardPreview: UploadProps["onPreview"] = (uploadFile) => {
      dialogImageUrl.value = uploadFile.url!;
      dialogVisible.value = true;
    };

    const getActivityStatus = () => {
      activityStatus.value = moment().isAfter(
        moment(currentActivity.value!.expired_date)
      )
        ? "Expired"
        : "On Going";
    };

    const currentUser = computed(() => {
      return authStore.currentUser;
    });

    watch(
      () => route.params.id,
      (state, prevState) => {
        initActivity();
        currentActivityRoom.value = null;
      }
    );

    onMounted(() => {
      initActivity();
      getTeacher();
      getYourActivityRoom();
    });

    return {
      currentActivity,
      initActivity,
      onSubmitActivityPost,
      postData,
      postRules,
      loading,
      formPostRef,
      posts,
      initPosts,
      fileList,
      handleRemove,
      handlePictureCardPreview,
      dialogVisible,
      dialogImageUrl,
      handleChange,
      uploadRef,
      acceptedTypes,
      postFileTempList,
      currentUser,
      activityAttachmentbaseURL,
      showImage,
      viewImageModalRef,
      imgAttached,
      fileAttached,
      currentActivityRoom,
      onRoomChange,
      isTeacher,
      activityStatus,
    };
  },
});
